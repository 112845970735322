<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
       Update Category
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCategoryFormalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Category Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category Name"
                
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCategoryForm.type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCategoryForm.link_id"
                  @input="removeSpace($event)"
                   @change="checkLink"
              />
              <small class="text-danger" v-if="!validLink">Not Valid</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Position "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Position Link"
                
              >
                <b-form-input
                  v-model="addCategoryForm.position"
                  id="position_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Tab image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >     <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://api.lmitac.com/${addCategoryForm.tab_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addCategoryForm.tab_image=''">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                        
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCategoryForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCategoryForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Icon image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://api.lmitac.com/${addCategoryForm.icon_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewEl.src = Tabimage
                addCategoryForm.icon_image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Icon Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCategoryForm.icon_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Icon image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_heIconader_image"
                        v-model="addCategoryForm.alt_icon_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import Vue from "vue";

export default {
  setup(props) {
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const validLink=ref(true)
    const checkLink=(link)=>{
       const formData = new FormData();

    
        
         
          formData.append("slug",link);
       store.dispatch('courses/checkLink',formData)
        .then(response => {

validLink.value=response.data.valid


        })
      

    }
    const refPreviewE2 = ref(null);

    const Tabimage = ref("media/svg/files/blank-image.svg");
    const { route } = useRouter();
    const id = route.value.params.id;
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id);
    store.dispatch("categories/GetCategory", { id }).then((response) => {
      store.commit("categories/GET_CATEGORY", response?.data);
    });

    const CategoryForm = computed(() => store.state.categories.category);
    const addCategoryForm = reactive(CategoryForm);
  
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
      
        refPreviewE2.value.src = base64;
      }
    );
    const addCategoryFormalidate = ref();
    const removeSpace = (target) => {
    
 addCategoryForm.value.link_id= target.toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, ``)
}
    const save = () => {
      console.log("CategoryForm", addCategoryForm);
if(validLink.value==true){
      const formData = new FormData();
      formData.append("link_id", addCategoryForm.value.link_id);

      formData.append("position", addCategoryForm.value.position);
      // formData.append("link_id", addCategoryForm.value.link_id);
      formData.append("type", addCategoryForm.value.type);
      formData.append("tab_image", addCategoryForm.value.tab_image);
      formData.append("icon_image", addCategoryForm.value.icon_image);
      formData.append("alt_tab_image", addCategoryForm.value.alt_tab_image);
      formData.append("_method", "put");
      formData.append("alt_icon_image", addCategoryForm.value.alt_icon_image);

      addCategoryFormalidate.value.validate().then((success) => {
        if (success) {
          store
            .dispatch("categories/UpdateCategory", { id, formData })
            .then((response) => {
              Vue.swal({
                icon: "success",
                title: "Updated!",

                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
        }
      else{
         Vue.swal({
              title: "",
              text: `Please Enter Valid Link`,
              icon: "error",
              confirmButtonText: "ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
      }
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCategoryForm,
      inputImageRenderer,
      addCategoryFormalidate,
      id,
      CategoryForm,
removeSpace,
  validLink,
     checkLink,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  
  </style>